<template>
<div>
  <Navbar />
  
  <section>
    <div id="first-page" class="xyk-container">
      <div>
        <h2>ΧΑΡΤΗΣ ΥΠΟΧΡΕΩΣΕΩΝ ΠΡΟΣ ΤΟΝ ΚΑΤΑΝΑΛΩΤΗ</h2>
        <img data-v-1daaa970="" src="/skroutz-last-mile-logo.svg" width="200" alt="skroutz last mile logo">
      </div>
      <span>ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε.</span>
    </div>

    <!-- Περιεχομενα -->
    <div class="xyk-container">
      <span>Αθήνα 2024</span>
      <div class="contents">
        <span style="text-decoration: underline;">Περιεχόμενα</span>
        <table>
          <tbody>
            <tr v-for="(el, index) in contents" :key="index">
              <td style="min-width: 85px;">ΚΕΦΑΛΑΙΟ {{ index + 1 }}</td>
              <td class="ml">{{ el }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- ΚΕΦΑΛΑΙΟ 1 -->
    <div class="xyk-container entity flex-start">
      <div class="head">
        <h3>ΚΕΦΑΛΑΙΟ 1</h3>
        <span>{{ contents[0] }}</span>
      </div>
      <p class="p-align mt-1">
        Η εταιρεία υπό τον διακριτικό τίτλο «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» λειτουργεί από το 2019.  
        Η πλήρης επωνυμία της είναι «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε.», εφεξής  και ως 
        «Eταιρεία» ή ως «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ». Ο παρακάτω Πίνακας δίνει όλες τις βασικές πληροφορίες 
        για την Εταιρεία «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ». Η Εταιρεία είναι εγγεγραμμένη στο Γενικό Εμπορικό 
        Μητρώο (Γ.Ε.Μ.Η.) με αριθμό Γ.Ε.Μ.Η.: 152424501000. Έδρα της Εταιρείας είναι  Αθήνα Αττικής.
      </p>
      <p class="p-align mt-1">
        Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» είναι εφοδιασμένη με την προβλεπόμενη από την κείμενη νομοθεσία Εθνική Γενική 
        Άδεια Παροχής Ταχυδρομικών Υπηρεσιών της Εθνικής Επιτροπής Τηλεπικοινωνιών και Ταχυδρομείων (ΕΕΤΤ) με αριθμό 20-060.
      </p>
      <table class="bussiness-tb">
        <tbody>
          <tr>
            <td>Επωνυμία:</td>
            <td>ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ ΜΟΝΟΠΡΟΣΩΠΗ Ι.Κ.Ε.».</td>
          </tr>
          <tr>
            <td>Έδρα Επιχείρησης:</td>
            <td>ΑΓΙΑΣ ΑΝΝΗΣ 7, 11855, ΑΘΗΝΑ, ΑΤΤΙΚΗΣ</td>
          </tr>
          <tr>
            <td>Τηλέφωνο:</td>
            <td>2111982107</td>
          </tr>
          <tr>
            <td>Email:</td>
            <td>help@skroutzdelivery.gr</td>
          </tr>
          <tr>
            <td>ΑΦΜ:</td>
            <td>801238128</td>
          </tr>
          <tr>
            <td>Δ.Ο.Υ.:</td>
            <td>ΚΕΦΟΔΕ ΑΤΤΙΚΗΣ</td>
          </tr>
          <tr>
            <td>Website:</td>
            <td>www.skroutzlastmile.gr</td>
          </tr>
        </tbody>
      </table>
      <div class="bussiness-specs">
        <h5>Συνοπτικά Χαρακτηριστικά της Εταιρείας:</h5>
        <p>Αριθμός εργαζομένων: περίπου 165 άτομα</p>
        <p>Αριθμός κέντρων διαλογής: 4</p>
        <p>Αριθμός φορτηγών: περισσότερα από 600 φορτηγά τύπου van ή μεγαλύτερα άνω του 1 τόνου από τα οποία 171 είναι ηλεκτρικά και 21 οχήματα κατηγορίας L7e</p>
        <p>Αριθμός διανομέων: περισσότερα από 6000 άτομα</p>
      </div>
      <div class="bussiness-specs">
        <h5>Οργανωτική Δομή (Οργανόγραμμα Εταιρείας):</h5>
        <p>
          Η Εταιρεία διοικείται από τους Διαχειριστές αυτής τον κ. Στέφανο Κατσίμπα και κ. Αγαμέμνονα Παπάζογλου, απασχολεί περίπου 180 άτομα και έχει τους ακόλουθους τομείς εσωτερικής οργάνωσης:
          <ul>
            <li>HR</li>
            <li>Finance</li>
            <li>Strategic Planning</li>
            <li>Routing</li>
            <li>Operations
              <ul>
              <li>Daily operations</li>
              <li>Warehouse</li>
              <li>Dispatch</li>
              <li>Drivers</li>
              </ul>
            </li>
            <li>Admin</li>
            <li>Engineering/Developement</li>
            <li>Support/Dispatch</li>
            <li>Driver HR</li>
          </ul>
        </p>
      </div>
      <div class="bussiness-specs">
        <h5>Ο Σκοπός της Εταιρείας σύμφωνα με το Άρθρο 3 του Καταστατικού της είναι:</h5>
        <p>
          Οι ταχυμεταφορές εγγράφων και αντικειμένων, οι υπηρεσίες κατ ́ οίκον
          παράδοσης τροφίμων (delivery), οι υπηρεσίες ταχυμεταφορών πολλαπλής
          φύσης, άλλες ταχυδρομικές και ταχυμεταφορικές υπηρεσίες που δεν
          κατονομάζονται,   οι   υπηρεσίες   παροχής   επιχειρηματικών   συμβουλών
          διαχείρισης, οι υπηρεσίες παροχής συμβουλών στρατηγικής διαχείρισης, οι
          υπηρεσίες διαμεσολάβησης στη μεταφορά αγαθών, υπηρεσίες εκπαίδευσης
          Π.Δ.Κ.Α., η παροχή ασφαλιστικής κάλυψης και η παροχή οδικής βοήθειας, η
          δυνατότητα φόρτισης, πώλησης και αντικατάστασης μπαταρίας καθώς και
          άλλων εξαρτημάτων του οχήματος μετά από επιτόπια διάγνωση, οι
          υπηρεσίες οδικής μεταφοράς εμπορευμάτων, άλλες υπηρεσίες οδικών
          μεταφορών εμπορευμάτων, ενοικίαση και εκμίσθωση φορτηγών, υπηρεσίες
          ενοικίασης   και   χρηματοδοτικής   μίσθωσης   οχημάτων   μεταφοράς
          εμπορευμάτων χωρίς οδηγό,
        </p>
        <p style="margin-top: 1rem;">καθώς και κάθε άλλη συναφής προς τα παραπάνω εργασία ή δραστηριότητα.</p>
      </div>
    </div>

    <!-- ΚΕΦΑΛΑΙΟ 2 -->
    <div class="xyk-container entity flex-start">
      <div class="head">
        <h3>ΚΕΦΑΛΑΙΟ 2</h3>
        <span>{{ contents[1] }}</span>
      </div>
      <p class="p-align mt-1">
        Η Εταιρεία παρέχει υπηρεσίες ταχυμεταφοράς ταχυδρομικών αντικειμένων με προορισμό το εσωτερικό και το εξωτερικό εντός της Ευρωπαϊκής Ένωσης. 
        Οι υπηρεσίες αυτές περιλαμβάνουν τη διακίνηση των αποστολών σε ταχυδρομικές διευθύνσεις ή σε θυρίδες αυτόματης παράδοσης παραλαβής ταχυδρομικών 
        αντικειμένων (lockers), προετοιμασία των ταχυδρομικών αντικειμένων, ανταλλαγή εγγράφων, καθώς και άλλες πρόσθετες υπηρεσίες όπως αγορά μέσω αντικαταβολής κ.λπ.
      </p>
      <p class="p-align mt-1">
        Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» είναι εφοδιασμένη με την προβλεπόμενη από την κείμενη νομοθεσία Εθνική Γενική 
        Άδεια Παροχής Ταχυδρομικών Υπηρεσιών της Εθνικής Επιτροπής Τηλεπικοινωνιών και Ταχυδρομείων (ΕΕΤΤ) με αριθμό 20-060.
      </p>
      <div class="bussiness-specs">
        <p>Ειδικότερα, οι παρεχόμενες υπηρεσίες και τα επιμέρους χαρακτηριστικά αυτών παρουσιάζονται παρακάτω:</p>
        <p class="service-cases">
          <span>Α. Παράδοση εντός εργάσιμων ημερών (συμπεριλαμβανομένου και του Σαββάτου)</span>
          <span>Β. Παράδοση σε έξυπνες θυρίδες (smart lockers)</span>
          <span>Γ. Ειδοποίηση εξέλιξης της παραγγελίας μέσω SMS</span>
          <span>Δ. Εξυπηρέτηση παραγγελιών εντός και εκτός αστικού κέντρου σε πόλεις που εξυπηρετεί η Εταιρεία σε όλη την ηπειρωτική Ελλάδα και τα νησιά.</span>
        </p>
      </div>
      <div class="bussiness-specs">
        <h5>Τρόπος Παρακολούθησης των Ταχυδρομικών Αντικειμένων – Ε.Σ.Π.Ε.Τ.Α.</h5>
        <p class="p-align mt-1">
          Η παρακολούθηση της παραλαβής, διακίνησης, διαλογής, μεταφοράς και παράδοσης των αποστολών των υπηρεσιών Ταχυμεταφορών 
          διενεργείται σύμφωνα με τα οριζόμενα στον ν. 4053/2021 και την απόφαση με αριθμό 1101/3/14.03.2024 της Εθνικής Επιτροπής 
          Τηλεπικοινωνιών και Ταχυδρομείων (Ε.Ε.Τ.Τ.) «Κανονισμός Γενικών και Ειδικών Αδειών και όρων παροχής Ταχυδρομικών Υπηρεσιών» και ισχύει σήμερα, 
          καθώς επίσης και στην απόφαση με αριθμό 687/328/05.04.2013 της Εθνικής Επιτροπής Τηλεπικοινωνιών και Ταχυδρομείων (Ε.Ε.Τ.Τ.) 
          «Κώδικας Δεοντολογίας Παροχής Ταχυδρομικών Υπηρεσιών» και ισχύει σήμερα.
        </p>
        <p class="p-align mt-1">
          Για τις υπηρεσίες ταχυμεταφορών, η παρακολούθηση και εντοπισμός ταχυδρομικών αντικειμένων και η πληροφόρηση του χρήστη 
          επιτυγχάνεται με τη χρήση εξειδικευμένου εξοπλισμού και λογισμικού, το οποίο επιτρέπει την ανταλλαγή ηλεκτρονικών δεδομένων 
          μεταξύ των εμπλεκομένων σταθμών του δικτύου της Εταιρείας. Το Ειδικό Σύστημα Παρακολούθησης και Εντοπισμού Ταχυδρομικών 
          Αντικειμένων (Ε.Σ.Π.Ε.Τ.Α.) παρακολουθεί και παρέχει αναλυτικά τα επιμέρους στοιχεία διακίνησης κάθε αποστολής όπως: παραλαβή, 
          άφιξη στο κατάστημα αποστολής, αναχώρηση από το κατάστημα αποστολής, άφιξη σε κέντρο διαλογής, κατανομή σε κατάστημα παράδοσης, 
          αναχώρηση από κέντρο διαλογής, άφιξη σε κατάστημα παράδοσης, κατανομή σε courier, άφιξη (επιστροφή) courier – παράδοση ή μη παράδοση, 
          στοιχεία Παραλήπτη. Τα στοιχεία των παραδόσεων των αποστολών διατίθενται μέσα από την ιστοσελίδα της Εταιρείας στη διεύθυνση 
          www.skroutzlastmile.gr και είναι στη διάθεση των χρηστών της 24 ώρες το 24ώρο και έως 6 μήνες από την ημερομηνία της αποστολής
        </p>
      </div>
      <div class="bussiness-specs">
        <h5>Χρόνοι Παράδοσης, Χρεώσεις και Λοιπές Διευκρινίσεις</h5>
        <p class="p-align mt-1">
          Η Εταιρεία καταρτίζει με σαφήνεια ακριβείς και ενημερωμένες συμβάσεις για την παροχή των ταχυδρομικών υπηρεσιών με τον εκάστοτε Πελάτη. 
          Η Εταιρεία διατηρεί το δικαίωμα αναμόρφωσης των χρεώσεων κάθε υπηρεσίας, κατόπιν σχετικής συμφωνίας με τον εκάστοτε Πελάτη της. 
          Γενικά, οι χρεώσεις των υπηρεσιών ποικίλουν ανάλογα με την υπηρεσία που επιλέγει ο Αποστολέας, τον προορισμό του Παραλήπτη και 
          τις διαστάσεις του ταχυδρομικού αντικειμένου, τα οποία υπολογίζονται βάσει του βάρους και του μεγέθους αυτού.
        </p>
        <p class="p-align mt-1">
          Οι υπηρεσίες διαχωρίζονται:
          <ol class="delivery-list">
            <li>Παράδοση σε φυσική διεύθυνση (με ή χωρίς αντικαταβολή) ή σε θυρίδα αυτόματης παράδοσης / παραλαβής ταχυδρομικών αντικειμένων εντός αστικού κέντρου
              <br /> 
              <strong> Παράδοση σε 1-3 ημέρες από τη παραλαβή </strong>
            </li>
            <li>Παράδοση σε φυσική διεύθυνση (με ή χωρίς αντικαταβολή) ή θυρίδα αυτόματης παράδοσης / παραλαβής ταχυδρομικών αντικειμένων εκτός αστικού κέντρου 
              και σε απομακρυσμένες περιοχές
              <br />
              <strong>  Παράδοση σε  1-4 ημέρες από την παραλαβή </strong>
            </li>
            <li>Ειδικές υπηρεσίες
              Επαναδρομολόγηση σε / από θυρίδα αυτόματης παράδοσης / παραλαβής
              <br />
              <strong>  Express: Εντός 1 εργάσιμης ημέρας από την παραλαβή </strong>
              </li>
          </ol>
        </p>
        <p class="p-align mt-1">
          Όλοι οι αναφερόμενοι χρόνοι παράδοσης αφορούν εργάσιμες ημέρες από την ημερομηνία παραλαβής του ταχυδρομικού αντικειμένου από τον Αποστολέα και υπολογίζονται 
          από την ημέρα αυτή ως την πρώτη προσπάθεια παράδοσης στα στοιχεία διεύθυνσης του Παραλήπτη.
        </p>
        <p class="p-align mt-1">
          Η παράδοση των αποστολών ταχυμεταφορών κατά κανόνα γίνεται στην διεύθυνση του Παραλήπτη, ή σε άλλη διεύθυνση κατόπιν αιτήματος ανακατεύθυνσης.
        </p>
        <p class="p-align mt-1">
          Η παράδοση αποστολών ταχυμεταφοράς αποδεικνύεται με την υπογραφή του Παραλήπτη και τα στοιχεία παράδοσης της αποστολής (ονοματεπώνυμο παραλαβόντος, 
          υπογραφή, ώρα και ημέρα παράδοσης). Η υπογραφή του Παραλήπτη μπορεί να είναι επί του αποδεικτικού παραλαβής – παράδοσης, ή ηλεκτρονικά στη συσκευή 
          για τη λήψη των δεδομένων (στοιχείων) παραλαβής και παράδοσης αποστολών (PDA).
        </p>
        <p class="p-align mt-1">
          Η υπογραφή του Παραλήπτη συνοδεύεται από τα αντίστοιχα στοιχεία παράδοσης (ώρα, ημερομηνία και ονοματεπώνυμο Παραλήπτη ολογράφως).
        </p>
        <p class="p-align mt-1">
          Εν γένει, στις υπηρεσίες ταχυμεταφορών δεν πραγματοποιείται πιστοποίηση της ταυτότητας του Παραλήπτη της αποστολής και λαμβάνεται το επίθετο/όνομα 
          του παραλαμβάνοντας (κατά δήλωση του), εκτός και αν έχει συμφωνηθεί εγγράφως διαφορετικά ή υφίσταται ειδική μεταχείριση με τη σχετική πρόσθετη υπηρεσία.
        </p>
        <p class="p-align mt-1">
          Η επιβεβαίωση παράδοσης της αποστολής επέρχεται επιπλέον και μέσω της καταχώρησης του μοναδικού προσωπικού κωδικού αναγνώρισης (pin) που θα έχει 
          αποσταλεί από την Εταιρεία ή μέσω επιβεβαιωτικού μηνύματος με ψηφιακό τρόπο.
        </p>
        <p class="p-align mt-1">
          Ως εργάσιμες ημέρες νοούνται οι ημέρες Δευτέρα έως και Σάββατο εκτός από τις αργίες και τοπικές εορτές.
        </p>
        <p class="p-align mt-1">
          Οι συνήθεις ώρες λειτουργίας της Εταιρείας για την εξυπηρέτηση των πελατών της είναι από Δευτέρα έως Σάββατο, 10:00 έως και 19:00, εφόσον δεν 
          είναι εθνικές ή τοπικές αργίες. Ειδικότερη ενημέρωση σχετικά με τις ώρες λειτουργίας της Εταιρείας δίδεται στην ιστοσελίδα της.
        </p>
      </div>
    </div>

    <!-- ΚΕΦΑΛΑΙΟ 3 -->
    <div class="xyk-container entity flex-start">
      <div class="head">
        <h3>ΚΕΦΑΛΑΙΟ 3</h3>
        <span>{{ contents[2] }}</span>
      </div>
      <p class="p-align mt-1">
        «Ποιότητα» σημαίνει παροχή υπηρεσιών υψηλού επιπέδου καλύπτοντας σε μεγάλο βαθμό τις ανάγκες και προσδοκίες των χρηστών. 
        Αυτό επιτυγχάνεται με σεβασμό στον χρήστη και εξυπηρέτηση των αναγκών του κατά τον καλύτερο δυνατό τρόπο. 
        Αυτός ο στόχος είναι συνυφασμένος με την απόλυτη κατανόηση των απαιτήσεων των χρηστών μας και την εγκατάσταση και διατήρηση ενός 
        περιβάλλοντος που υποστηρίζει και ενθαρρύνει όλο το προσωπικό της Εταιρείας σε συνεχή βελτίωση των παρεχομένων υπηρεσιών.
      </p>
      <div class="bussiness-specs">
        <p>
          Για την επίτευξη αυτού του στόχου, η Διοίκηση της Εταιρείας δεσμεύεται να εξασφαλίζει διαρκώς:
          <ul >
            <li>την πλήρη, άμεση και συστηματική κάλυψη των δηλωμένων αναγκών των χρηστών της,</li>
            <li>την υψηλή ποιότητα των προϊόντων/υπηρεσιών της, εξακριβωμένη και επιβεβαιούμενη με συνεχείς ελέγχους και μετρήσεις, και</li>
            <li>τη συμμόρφωση των προϊόντων/υπηρεσιών της ως προς τις ισχύουσες νομοθετικές και κανονιστικές απαιτήσεις της ελληνικής και της ευρωπαϊκής νομοθεσίας.</li>
          </ul>
        </p>
        <p>
          Τα ανωτέρω επιτυγχάνονται μέσω: 
          <ul >
            <li>του καθορισμού στόχων στο πλαίσιο του Επιχειρηματικού Σχεδίου δράσης της Διοίκησης,</li>
            <li>της οργάνωσης και διάθεσης όλων των απαιτούμενων πόρων και μέσων για την εξασφάλιση της απρόσκοπτης, αποδοτικής και αποτελεσματικής λειτουργίας της Εταιρείας,</li>
            <li>της δημιουργίας ενός τεχνολογικά σύγχρονου και επαγγελματικά κατάλληλου περιβάλλοντος εργασίας,</li>
            <li>της διατήρησης του υψηλού επαγγελματικού επιπέδου των στελεχών μέσα από την συνεχή εκπαίδευση και αξιολόγηση,</li>
            <li>της διαρκούς μέτρησης και της αδιάλειπτης παρακολούθησης κρίσιμων παραμέτρων και διεργασιών ώστε να εξασφαλίζεται η ποιότητα και η ασφάλεια εγκαταστάσεων, προϊόντων/υπηρεσιών και προσωπικού, και</li>
            <li>της ορθής επιλογής και υποστήριξης των εξωτερικών συνεργατών της.</li>
          </ul>
        </p>
      </div>
    </div>

    <!-- ΚΕΦΑΛΑΙΟ 4 -->
    <div class="xyk-container entity flex-start">
      <div class="head">
        <h3>ΚΕΦΑΛΑΙΟ 4</h3>
        <span>{{ contents[3] }}</span>
      </div>
      <p class="p-align mt-1">
        Κάθε διακινούμενη αποστολή συνοδεύεται από Συνοδευτικό Δελτίο Ταχυμεταφοράς (ΣΥ.ΔΕ.ΤΑ.) της Εταιρείας όπου δηλώνονται 
        ευκρινώς από τον Αποστολέα - ο οποίος έχει και την πλήρη ευθύνη σύνταξης των σωστών στοιχείων - τα πλήρη στοιχεία του ιδίου, 
        του Παραλήπτη, η ημερομηνία και ώρα παραλαβής, καθώς και οι υπηρεσίες που επιθυμεί. Η σύνταξη του ΣΥ.ΔΕ.ΤΑ. και η ανάληψη 
        της διακίνησης από την Εταιρεία πιστοποιεί την κατάρτιση ατομικής σύμβασης μεταξύ του χρήστη των υπηρεσιών και της 
        Εταιρείας «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» και αποδεικνύει την αποδοχή των γενικών όρων ανάληψης μεταφοράς από τα εμπλεκόμενα 
        μέρη. Τα βασικά θέματα που ορίζονται μέσω της ατομικής σύμβασης είναι τα παρακάτω: 
      </p>
      <div class="bussiness-specs">
        <p>
          <ul >
            <li>τα μη αποδεκτά αντικείμενα καθώς και τα αντικείμενα που απαγορεύεται η μεταφορά τους σύμφωνα με Διεθνείς, Εθνικές και Ευρωπαϊκές συμβάσεις,</li>
            <li>το δικαίωμα της επιχείρησης να μην παραλαμβάνει προς διακίνηση αντικείμενα εφόσον ο Αποστολέας αρνηθεί την επιθεώρηση τους από εκπρόσωπο της επιχείρησης,</li>
            <li>οι όροι Ασφάλισης του ταχυδρομικού αντικειμένου, και τα ποσά αυτής,</li>
            <li>
              οι αποζημιώσεις για καθυστερήσεις, απώλεια και φθορά αντικειμένων και οι προθεσμίες εντός των οποίων μπορεί να τις διεκδικήσει ο χρήστης, 
              κατ’ ελάχιστο σύμφωνα με την Υπουργική Απόφαση 29030/816/2.6.2000 (ΦΕΚ 683/Β΄),
            </li>
            <li>οι περιπτώσεις κατά τις οποίες δεν ευθύνεται η ταχυδρομική επιχείρηση,</li>
            <li>ο τρόπος διαχείρισης ανεπίδοτων ταχυδρομικών αντικειμένων, και</li>
            <li>η διαδικασία επίλυσης διαφορών μεταξύ επιχείρησης και χρήστη.</li>
          </ul>
        </p>
      </div>
      <p class="p-align mt-1">
        Ο Αποστολέας αναθέτοντας τη διακίνηση της αποστολής του στη «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» αποδέχεται τους όρους 
        μεταφοράς της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» τόσο για τον εαυτό του όσο και υπό την ιδιότητα του εκπροσώπου του 
        εντολέα, κυρίου ή κατόχου των υπό μεταφορά αγαθών. 
      </p>
      <p class="p-align mt-1">
        Τα ανωτέρω ισχύουν και στις περιπτώσεις κατά τις οποίες η Εταιρεία αναλαμβάνει τη διακίνηση μαζικών αποστολών 
        όπου η διακινούμενη αποστολή συνοδεύεται από απλοποιημένο ΣΥ.ΔΕ.ΤΑ.
      </p>
      <p class="p-align mt-1">
        Εφόσον δεν υφίσταται διαφορετική συμφωνία, η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» έχει την υποχρέωση επίδοσης του αντικειμένου 
        στη διεύθυνση του Παραλήπτη ή σε τρίτο πρόσωπο στη διεύθυνση αυτή, σε περίπτωση απουσίας του Παραλήπτη. Στην παράδοση 
        ο Παραλήπτης αναγράφει ευκρινώς το όνομα του, την ημερομηνία και ώρα παραλαβής και υπογράφει επί του αποδεικτικού 
        παραλαβής – παράδοσης, ή ηλεκτρονικά στη συσκευή για τη λήψη των δεδομένων (στοιχείων) παραλαβής και παράδοσης αποστολών (PDA). 
      </p>
      <p class="p-align mt-1">
        Η ευθύνη της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» για τις μεταφερόμενες αποστολές παύει να ισχύει την στιγμή της παράδοσης της 
        εκάστοτε αποστολής στον Παραλήπτη ή στο τυχόν τρίτο πρόσωπο στη δηλωθείσα διεύθυνση, σε περίπτωση απουσίας του Παραλήπτη. 
        Η υπογραφή του Παραλήπτη ή του τυχόν τρίτου προσώπου κατά τα ανωτέρω, με την αναγραφή των στοιχείων παράδοσης πάνω στο 
        αντίγραφο του ΣΥ.ΔΕ.ΤΑ.,  καθώς και η καταχώρηση του μοναδικού προσωπικού κωδικού αναγνώρισης (PIN) που έχει αποσταλεί από την Εταιρεία, 
        ή την ψηφιακή είσοδο στην αντίστοιχη εφαρμογή, αποτελούν σαφή απόδειξη του ορθού της παράδοσης.

      </p>
      <p class="p-align mt-1">
        Όλες οι διακινούμενες από την Εταιρεία αποστολές παρακολουθούνται και εντοπίζονται ηλεκτρονικά σε όλα τα στάδια της 
        μεταφοράς τους μέσω Ε.Σ.Π.Ε.Τ.Α. Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» διατηρεί πληροφορίες διακίνησης μέσω Ε.Σ.Π.Ε.Τ.Α. 
        καθώς και αρχείο ΣΥ.ΔΕ.ΤΑ. σε ηλεκτρονική ή έντυπη μορφή για έξι μήνες από την ημερομηνία έναρξης της αποστολής.
      </p>
      <p class="p-align mt-1">
        Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» καταβάλλει κάθε δυνατή προσπάθεια προκειμένου να παραδώσει την αποστολή σύμφωνα 
        με τα συνήθη χρονοδιαγράμματα διακίνησης της εκάστοτε προσφερόμενης υπηρεσίας. Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» 
        δεν ευθύνεται για οποιαδήποτε θετική ή αποθετική ζημία που προκαλείται από τυχόν καθυστερήσεις, πέραν των όσων 
        ορίζονται στους Όρους και Προϋποθέσεις ταχυμεταφοράς, όπως αναλύονται κάτωθι. Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» είναι 
        σε θέση να ενημερώνει άμεσα για την τύχη της αποστολής τον Αποστολέα και τον Παραλήπτη αρκεί να της γνωστοποιούν 
        τον αριθμό της φορτωτικής (ΣΥ.ΔΕ.ΤΑ.). Αναλυτικά οι Όροι και Προϋποθέσεις ανάληψης μεταφοράς είναι οι εξής:
      </p>
      <div class="bussiness-specs">
        <h5>Όροι και Προϋποθέσεις Ανάληψης Μεταφοράς Εγγράφων & Δεμάτων από τη «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ»:</h5>
        <p class="p-align mt-1">
          Για την μεταφορά δεμάτων και εγγράφων μέσω του συστήματος αποστολής και διεκπεραίωσης της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ», 
          ο Αποστολέας συμφωνεί με τους Όρους και τις Προϋποθέσεις, που αναφέρονται στο παρόν έντυπο. Ο οποιοσδήποτε εμπλεκόμενος - εκπρόσωπος, 
          πράκτορας ή υπάλληλος της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» - ή ο Αποστολέας ή Παραλήπτης δεν έχει το δικαίωμα να τροποποιήσει
          ή να άρει τους αναφερόμενους όρους και προϋποθέσεις.
        </p>
        <div class="general-terms">
          1. <span>ΓΕΝΙΚΟΙ ΟΡΟΙ:</span>
        </div>
        <p class="p-align mt-1">
          A. Το Συνοδευτικό Δελτίο Ταχυμεταφοράς (ΣΥ.ΔΕ.ΤΑ.) της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» είναι έντυπο μη διαπραγματεύσιμο και ο 
          Αποστολέας αναγνωρίζει ότι συμπληρώθηκε από αυτόν ή από την «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» για λογαριασμό του. Ο Αποστολέας 
          δηλώνει και αποδέχεται ότι είναι κύριος ή κάτοχος ή εκπρόσωπος του κυρίου ή του κατόχου των υπό μεταφορά αγαθών. Επίσης, 
          δηλώνει ότι με το παρόν αποδέχεται ανεπιφύλακτα τους όρους μεταφοράς της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ»» τόσο για τον εαυτό 
          του όσο και υπό την ιδιότητα του εκπροσώπου του εντολέα, κυρίου ή κατόχου, των υπό μεταφορά αγαθών.
        </p>
        <p class="p-align mt-1">
          B. Ο Αποστολέας εγγυάται ότι το υπό διεκπεραίωση αντικείμενο:
          <ul class="grammar-el">
            <li>α) δεν εμπίπτει στα μη αποδεκτά από τη «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» Ταχυδρομικά 	Αντικείμενα,</li>
            <li>β) φέρει σωστά τα απαιτούμενα στοιχεία Αποστολέα και Παραλήπτη,</li>
            <li>γ) έχει συσκευασθεί κατάλληλα ώστε να εξασφαλιστεί η ασφαλής διεκπεραίωση.</li>
          </ul>
        </p>
        <p class="p-align mt-1">
          Πρόσθετα έξοδα που τυχόν προκύψουν κατά τη διεκπεραίωση (π.χ. επιστροφή ή αποθήκευση) της Αποστολής θα καταβληθούν από τον Αποστολέα/Εντολέα.
        </p>
        <p class="p-align mt-1">
          Γ. Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» δικαιούται αλλά δεν υποχρεούται, να προβεί σε έλεγχο του περιεχομένου της αποστολής την 
          οποία αναλαμβάνει να διεκπεραιώσει. Επιπλέον έχει το δικαίωμα να αρνηθεί την παραλαβή του αντικειμένου, εφόσον ο Αποστολέας 
          αρνηθεί τον έλεγχο αυτού. Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» έχει το δικαίωμα να διακόψει τη διεκπεραίωση κάποιου αντικειμένου 
          όταν υπάρχουν ενδείξεις ότι αυτό εμπίπτει στα μη αποδεκτά από τη «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» Ταχυδρομικά Αντικείμενα ή 
          όταν ο Αποστολέας για οποιονδήποτε λόγο έδωσε ελλιπή ή λανθασμένα για τη διεκπεραίωση στοιχεία ή τέλος αν δεν έχουν υποβληθεί
          τα απαραίτητα έγγραφα που απαιτούνται από τις αρμόδιες τελωνειακές αρχές.
        </p>
        <p class="p-align mt-1">
          Δ. Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» έχει το δικαίωμα να παρακρατήσει ή να αρνηθεί την παράδοση κάθε είδους το οποίο μεταφέρει, 
          προκειμένου να εξασφαλίσει οποιαδήποτε δαπάνη προκύπτει από την εν λόγω μεταφορά ή τυχόν προγενέστερες (ναύλοι, δασμοί, 
          φορτωτικές κ.λπ.), μέχρι να εξοφληθεί πλήρως.
        </p>
        <p class="p-align mt-1">
          Ε. Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» αναλαμβάνει τη διεκπεραίωση αποστολών με χρέωση της αμοιβής της στον Παραλήπτη. Σε περίπτωση 
          μη καταβολής της παραπάνω αμοιβής για οποιονδήποτε λόγο, την υποχρέωση εξόφλησης της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» αναλαμβάνει ο Αποστολέας.
        </p>
        <p class="p-align mt-1">
          Ζ. Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» διεκπεραιώνει κάθε είδους αποστολή (π.χ. αγορά, αντικαταβολή κ.λπ.) και με μη έγγραφη εντολή (π.χ. ηλεκτρονική, τηλεφωνική). 
          Στις περιπτώσεις αυτές ο εντολέας έχει τα δικαιώματα και τις υποχρεώσεις του αναγραφόμενου ως Αποστολέα στο ΣΥ.ΔΕ.ΤΑ.
        </p>
        <div class="general-terms">
          2. <span>ΕΥΘΥΝΗ ΕΤΑΙΡΕΙΑΣ – ΑΠΟΖΗΜΙΩΣΕΙΣ:</span>
        </div>
        <p class="p-align mt-1">Α. Ορισμοί:</p>
        <p class="p-align mt-1">
          α) <u>Ολική Απώλεια</u> νοείται η αδυναμία εντοπισμού του αντικειμένου μετά την παρέλευση 5 εργασίμων ημερών από την ημερομηνία παραλαβής. 
        </p>
        <p class="p-align mt-1">
          β) <u>Μερική Απώλεια</u> νοείται η απώλεια μέρους της αρχικής αποστολής είτε λόγω ζημίας της εξωτερικής συσκευασίας που αιτιολογεί 
          την μερική απώλεια περιεχομένου, είτε λόγω μη παράδοσης ενός ή και περισσότερων φακέλων/δεμάτων από το σύνολο των φακέλων/δεμάτων 
          που αποτελούσαν την αρχική αποστολή πριν ή έως και κατά την ώρα της παράδοσης. 
        </p>
        <p class="p-align mt-1">
          γ) <u>Ολική Καταστροφή</u> νοείται η ολοσχερής καταστροφή αντικειμένου λόγω πλημμελούς μεταφοράς του. 
        </p>
        <p class="p-align mt-1">
          δ) <u>Μερική Καταστροφή</u> νοείται η καταστροφή μέρους της αποστολής λόγω πλημμελούς μεταφοράς. 
        </p>
        <p class="p-align mt-1">
          ε) <u>Ανωτέρα Βία</u>: Απρόοπτα γεγονότα κείμενα πέρα των ανθρωπίνων αντικειμενικά δυνατοτήτων πρόβλεψης και αποφυγής (ατυχήματα, 
          δυσμενείς καιρικές συνθήκες, αργοπορία μεταφορικών μέσων, απεργίες, συγκοινωνιακά ή τηλεπικοινωνιακά προβλήματα κ.λπ.). 
        </p>
        <p class="p-align mt-1">
          στ) Ως <u>πραγματική αξία</u> νοείται: 
          <ul class="dash">
            <li>
              για τα έγγραφα: το κόστος προετοιμασίας, αντικατάστασης, αναπαραγωγής, ή ανασύνταξης αυτών
            </li>
            <li>
              για τα αντικείμενα: το κόστος επισκευής, αντικατάστασης ή αγοράς αυτών, ως αποδεικνύεται από τα προσκομιζόμενα παραστατικά, 
              (στην κατάσταση που ήταν κατά την παραλαβή), λαμβανομένης της κάθε φορά μικρότερης αξίας που αναφέρεται πιο κάτω, η οποία 
              σε καμία περίπτωση δεν μπορεί να είναι μεγαλύτερη των ορίων ποσού που ορίζονται στην Απόφαση ΕΕΤΤ 688/52/16.04.2013 
              (ΦΕΚ 1412/Β/2013) για τις περιπτώσεις αποδεδειγμένης κλοπής, ολικής απώλειας ή ολικής καταστροφής.
            </li>
          </ul>
        </p>
        <p class="p-align mt-1">
          Β. Η πλημμελής παροχή υπηρεσιών γεννά δικαίωμα κατ’ αποκοπήν αποζημίωσης. Κάθε καταναλωτής μπορεί να υποβάλει έγγραφη διαμαρτυρία 
          για τυχόν απώλειες ή ζημιές που έχουν προκληθεί στην αποστολή του ή για κλοπή αυτής ή για καθυστέρηση επίδοσης πέραν του 
          συμφωνηθέντος χρονικού διαστήματος εντός έξι (6) μηνών το αργότερο από την ημερομηνία παραλαβής και να αιτήσει αποζημίωση 
          σύμφωνα με τα προβλεπόμενα όρια αποζημίωσης αναλόγως της υπηρεσίας και του είδους της πλημμέλειας Η Εταιρεία απαλλάσσεται σε 
          κάθε περίπτωση από οποιαδήποτε ευθύνη εξ’ οιασδήποτε αιτίας σχετικά με πλημμελή εκπλήρωση ταχυδρομικών υπηρεσιών εφόσον δεν έχει 
          υποβληθεί από το χρήστη σχετική απαίτηση εντός έξι (6) μηνών από την κατάρτιση της ταχυδρομικής σύμβασης, δηλαδή από την ανάληψη 
          του εκάστοτε ταχυδρομικού αντικειμένου από την Εταιρεία εκ μέρους του Αποστολέα.
        </p>
        <p class="p-align mt-1">
          Γ. Σε περίπτωση που αποδειχθεί, μέσω των αρμοδίων οργάνων της Εταιρείας, ότι την ευθύνη για την ζημιά, απώλεια, κλοπή ή καθυστέρηση 
          παράδοσης φέρει η Εταιρεία τότε το Τμήμα Εξυπηρέτησης Πελατών της Εταιρείας θα ενημερώσει τον χρήστη για την απόφαση και θα ζητήσει 
          από τον δικαιούχο χρήστη την προσκόμιση των απαραίτητων δικαιολογητικών για την αποζημίωση του. Κατόπιν και υπό την προϋπόθεση ότι 
          ο χρήστης δεν έχει οφειλές προς την Εταιρεία, η καταβολή της αποζημίωσης γίνεται εντός πέντε (5) εργασίμων ημερών από την 
          ημερομηνία της έγκρισης και της παραλαβής αυτών των δικαιολογητικών, αποδεικνυόμενης με αποδεικτικό παραλαβής – παράδοσης.
        </p>
        <p class="p-align mt-1">
          Δ. Οι περιπτώσεις που επιβάλλεται κατ’ αποκοπήν αποζημίωση για πλημμελή παροχή ταχυδρομικών υπηρεσιών από την Εταιρεία προς τον 
          χρήστη για τις υπηρεσίες ταχυμεταφορών καθορίζονται από την Απόφαση ΕΕΤΤ 688/52/16.04.2013 (ΦΕΚ 1412/Β/2013).
        </p>
        <p class="p-align mt-1">
          Ε. Η ευθύνη της Εταιρείας για πλημμελή παροχή υπηρεσιών ταχυμεταφορών, για τους μεμονωμένους χρήστες (αποστολείς η Παραλήπτες ή 
          εντολείς οι οποίοι, πέραν της ατομικής σύμβασης, δεν έχουν συνάψει άλλη ιδιαίτερη σύμβαση για την διακίνηση πλήθους ταχυδρομικών 
          αντικειμένων ή/και για παροχή ταχυδρομικών υπηρεσιών για μεγάλα χρονικά διαστήματα) προσδιορίζεται, ως ακολούθως:
          <ul class="roman">
            <li>
              Για αποδεδειγμένη απώλεια ή ολική κλοπή ή ολική καταστροφή του περιεχομένου ενός φακέλου, με ευθύνη της Εταιρείας, το οποίο 
              φέρει μονοσήμαντα ορισμένο αριθμό 	αποστολής, καταβάλλεται ελάχιστη αποζημίωση 50€ και μέγιστη αποζημίωση 70€ και επιστροφή 
              του ταχυδρομικού τέλους για τη συγκεκριμένη αποστολή.
            </li>
            <li>
              Για αποδεδειγμένη απώλεια ή ολική κλοπή ή ολική καταστροφή του περιεχομένου δέματος με ευθύνη της Εταιρίας χωρίς δηλωμένη αξία, 
              καταβάλλεται ελάχιστη αποζημίωση 100€ και μέγιστη αποζημίωση 400€ και επιστροφή του καταβληθέντος ταχυδρομικού τέλους για τη συγκεκριμένη αποστολή.
            </li>
            <li>
              Για αποδεδειγμένη απώλεια ή ολική κλοπή ή ολική καταστροφή του περιεχομένου φακέλου ή δέματος με δηλωμένη αξία με ευθύνη της 
              Εταιρείας, καταβάλλεται αποζημίωση ίση με 	το ποσό της αξίας και επιστρέφεται το καταβληθέν ταχυδρομικό τέλος για τη συγκεκριμένη αποστολή.
            </li>
            <li>
              Για αποδεδειγμένη μερική απώλεια ή μερική κλοπή ή μερική καταστροφή περιεχομένου 	φακέλου με μονοσήμαντα ορισμένο αριθμό αποστολής 
              και δέματος με ευθύνη της Εταιρείας, καταβάλλεται αποζημίωση ίση με την πραγματική αξία της απώλειας ή κλοπής ή καταστροφής, 
              με την προϋπόθεση ότι δεν υπερβαίνει το ποσό της αποζημίωσης για απώλεια ή ολική κλοπή ή ολική καταστροφή και επιστρέφεται το 
              καταβληθέν ταχυδρομικό τέλος για τη συγκεκριμένη αποστολή.
            </li>
            <li>
              Για αποδεδειγμένη μερική απώλεια ή μερική κλοπή ή μερική καταστροφή περιεχομένου 	φακέλου ή δέματος με δηλωμένη αξία/ασφαλισμένου 
              με ευθύνη της Εταιρείας, καταβάλλεται αποζημίωση ίση με την πραγματική αξία της απώλειας ή κλοπής ή καταστροφής, και έως του ποσού 
              ασφαλισμένης αξίας και επιστρέφεται το καταβληθέν ταχυδρομικό τέλος για τη συγκεκριμένη αποστολή.
            </li>
            <li>
              Εάν ένα δέμα ή ένα ταχυδρομικό αντικείμενο ταχυμεταφορών 	επιστραφεί και η αιτία της μη επίδοσής του είναι άγνωστη με ευθύνη της Εταιρείας, 
              ή δεν παραδοθεί με ευθύνη της Εταιρείας και επιστραφεί, ο Αποστολέας δικαιούται να ζητήσει την επιστροφή των ταχυδρομικών	εξόδων. 
            </li>
            <li>
              Για αποδεδειγμένη καθυστέρηση επίδοσης ταχυδρομικών αντικειμένων ταχυμεταφορών, πέραν των ορισμένων ανά υπηρεσία χρόνων παράδοσης, καταβάλλεται	
              αποζημίωση ίση με 6€ για κάθε ημέρα καθυστέρησης και για την περίπτωση που η καθυστέρηση υπερβαίνει το πενταπλάσιο του συμφωνημένου χρόνου 
              επίδοσης, επιπλέον επιστροφή του καταβληθέντος ταχυδρομικού τέλους για τη συγκεκριμένη αποστολή, με την προϋπόθεση ότι το συνολικό ποσό της 
              αποζημίωσης δεν υπερβαίνει τα 100€. 
            </li>
            <li>
              Η αποζημίωση για ηθική βλάβη συμπεριλαμβάνεται στα προαναφερθέντα ποσά, ενώ η συνεπαγόμενη απώλεια κέρδους δεν αποζημιώνεται.
            </li>
            <li>
              Σε πολλαπλή αποστολή, όπου αποστέλλονται πολλά ταχυδρομικά αντικείμενα προς έναν Παραλήπτη και που εμπίπτει στις ανωτέρω περιπτώσεις, κάθε 
              ταχυδρομικό αντικείμενο τυγχάνει διαφορετικής αντιμετώπισης και αποζημιώνεται ξεχωριστά.
            </li>
            <li>
              Οι αποζημιώσεις των ανωτέρω περιπτώσεων καταβάλλονται στον Αποστολέα εφόσον αυτός είναι ο ιδιοκτήτης, ή αν αυτός παραιτηθεί των δικαιωμάτων 
              του υπέρ του Παραλήπτη εφόσον αυτός είναι ο ιδιοκτήτης της αποστολής, στον Παραλήπτη του ταχυδρομικού αντικειμένου, ή σε τρίτον εφόσον αυτός 
              είναι ο εντολέας και ιδιοκτήτης της παραλαβής.
            </li>
          </ul>
        </p>
        <p class="p-align mt-1">
          Ζ. Διευκρινίζεται ότι ειδικά για τους χρήστες των υπηρεσιών με τους οποίους έχουν συναφθεί ή συνάπτονται - εν είδει ατομικής 
          σύμβασης - συμβάσεις/συμφωνίες για την διακίνηση πλήθους ταχυδρομικών αντικειμένων ή/και για παροχή ταχυδρομικών υπηρεσιών για 
          μεγάλα χρονικά διαστήματα και στους οποίους παρέχονται εκπτώσεις επί των βασικών τιμών του τιμοκαταλόγου της Εταιρείας ή/και 
          ειδικές χαμηλότερες τιμές σε σχέση με τις βασικές τιμές των εν λόγω υπηρεσιών, ή/και πίστωση για την παροχή των υπηρεσιών με την 
          χρήση κωδικού, ισχύουν οι ειδικότεροι όροι και συμφωνίες που διαλαμβάνονται στις συμβάσεις αυτές. Γίνεται μνεία ότι στις περιπτώσεις 
          σύναψης των ειδικότερων αυτών συμφωνιών δεν τυγχάνουν εφαρμογής τα όρια και ποσά αποζημιώσεων της ανωτέρω παραγράφου, αλλά ισχύουν 
          οι ειδικότεροι συμφωνηθέντες όροι δυνάμει των οποίων σε κάθε περίπτωση η εκάστοτε καταβαλλόμενη αποζημίωση για ολική απώλεια, κλοπή, 
          καταστροφή ανασφάλιστου φακέλου ή δέματος δεν δύναται να υπερβαίνει την πραγματική αξία του ή την πραγματική αξία του/των δέματος/δεμάτων.
        </p>
        <p class="p-align mt-1">
          Ως πραγματική αξία νοείται :
          <ul>
            <li>
              για τα έγγραφα το κόστος προετοιμασίας, αντικατάστασης, αναπαραγωγής, ή ανασύνταξης	αυτών,
            </li>
            <li>
              για τα αντικείμενα το κόστος επισκευής, αντικατάστασης ή αγοράς αυτών, ως 	αποδεικνύεται από τα προσκομιζόμενα 
              παραστατικά, (στην κατάσταση που ήταν κατά την 	παραλαβή).
            </li>
          </ul>
        </p>
        <p class="p-align mt-1">
          Η. Ο κατά τα ανωτέρω περιορισμός της αποζημίωσης με τη χορήγηση κατ’ αποκοπήν αποζημίωσης καταλαμβάνει κάθε απαίτηση του χρήστη που 
          απορρέει από την παροχή ταχυδρομικών υπηρεσιών, είτε από σύμβαση είτε από αδικοπραξία, και δεν ισχύει εάν η παράβαση της συμβατικής 
          υποχρέωσης ή η αδικοπραξία ανάγεται σε δόλο του φορέα παροχής ταχυδρομικών υπηρεσιών ή των προσώπων που ενεργούν για λογαριασμό του. 
          Η Εταιρεία ευθύνεται μόνο για τις περιπτώσεις και για τα ποσά που ορίζονται ανωτέρω και σε καμία περίπτωση δεν φέρει επιπλέον ευθύνη 
          για κανένα είδος απώλειας ή ζημίας (συμπεριλαμβανομένων ενδεικτικά κάθε είδους θετικών ή αποθετικών ζημιών ή διαφυγόντων κερδών), 
          ακόμα και αν ο κίνδυνος μιας τέτοιας απώλειας ή ζημίας τέθηκε υπόψη της Εταιρείας πριν ή μετά την αποδοχή της αποστολής. Τυχόν 
          απαιτήσεις του Αποστολέα ή τρίτων περιορίζονται σε μια μόνο αξίωση αποζημίωσης ανά αποστολή και μέχρι του ποσού που ανωτέρω ρητά 
          ορίζεται, η διευθέτηση της οποίας αποτελεί πλήρη και τελική διευθέτηση για κάθε απώλεια ή ζημία σε σχέση με την αποστολή. Ο 
          Αποστολέας αποδέχεται ανεπιφύλακτα τα παραπάνω οριζόμενα ποσά αποζημίωσης και δηλώνει ότι παραιτείται από κάθε επιπλέον αξίωσή 
          του κατά της Εταιρείας για κάθε θετική ή αποθετική ζημία ή διαφυγόν κέρδος από οποιαδήποτε αιτία και αν προέρχονται.
        </p>
        <p class="p-align mt-1">
          Θ. Η Εταιρεία δε δύναται να γνωρίζει το περιεχόμενο των αποστολών. Ο Εντολέας/Αποστολέας, ο οποίος γνωρίζει το περιεχόμενο, 
          αναγνωρίζει και αποδέχεται ανεπιφύλακτα ότι η αξία του περιεχομένου στην περίπτωση που δεν προβεί ο ίδιος στην ασφάλιση του δια 
          της Εταιρείας καταβάλλοντας το αντίστοιχο τίμημα είναι σε κάθε περίπτωση χαμηλότερη από τα αναγραφόμενα όρια των ανωτέρω παραγράφων 
          φέρει δε ο ίδιος την πλήρη ευθύνη για τις οποιεσδήποτε τυχόν υπερβάσεις των ανωτέρω ορίων, σε οποιαδήποτε περίπτωση απώλειας ή ζημιάς της αποστολής.
        </p>
        <p class="p-align mt-1">
          Ι. Η Εταιρεία καταβάλλει κάθε προσπάθεια και προβαίνει σε κάθε ενέργεια για να πετύχει ταχύτατη παράδοση σύμφωνα με το πρόγραμμα 
          λειτουργίας της και τους χρόνους παράδοσης της εκάστοτε υπηρεσίας, έτσι όπως αυτοί εξειδικεύονται στα συμβατικώς οριζόμενα με 
          τον εκάστοτε Πελάτη. Σε κάθε περίπτωση, η Εταιρεία δεν ευθύνεται για οποιαδήποτε ζημία ή απώλεια που προκαλείται από τυχόν 
          καθυστερήσεις παράδοσης των αποστολών πέραν των ορίων που ορίζονται στην Απόφαση Ε.Ε.Τ.Τ. 688/52/16.04.2013 (ΦΕΚ 1412/Β/2013).
        </p>
        <p class="p-align mt-1">
          Κ. Η ευθύνη της Εταιρείας ρητά περιορίζεται έως το ανώτερο των ποσών που ορίζονται, κατά περίπτωση στις ανωτέρω παραγράφους, 
          και σε καμία περίπτωση δεν φέρει επιπλέον ευθύνη για κανένα είδος απώλειας ή ζημίας (συμπεριλαμβανομένων ενδεικτικά κάθε είδους 
          θετικών ή αποθετικών ζημιών ή διαφυγόντων κερδών), ακόμα και αν ο κίνδυνος μιας τέτοιας απώλειας ή ζημίας τέθηκε υπόψη της 
          Εταιρείας πριν ή μετά την αποδοχή της αποστολής. Ο Αποστολέας αποδέχεται ανεπιφύλακτα τα παραπάνω οριζόμενα ποσά αποζημίωσης 
          και δηλώνει ότι παραιτείται από κάθε επιπλέον αξίωσή του κατά της Εταιρείας για κάθε θετική ή αποθετική ζημία ή διαφυγόν κέρδος 
          από οποιαδήποτε αιτία και αν προέρχονται. Τυχόν διαφορετική, σε σχέση με τους χρόνους παράδοσης, κατανόηση του χρήστη ή συμπλήρωση, 
          παρατήρηση, ή επισήμανση επί του αποδεικτικού παραλαβής παράδοσης ή επί της αποστολής δεν ισχύει και γίνεται με δική του ευθύνη. 
          Σε καμία όμως περίπτωση η Εταιρεία δεν φέρει ευθύνη για καθυστέρηση παραλαβής, μεταφοράς ή παράδοσης κάθε αποστολής ή για οποιαδήποτε 
          απώλεια, θετική ή αποθετική ζημία ή διαφυγόν κέρδος, βλάβη, κακή παράδοση ή μη παράδοση οφειλόμενη σε ανωτέρα βία ή σε λοιπές 
          απρόβλεπτες αιτίες, όπως ενδεικτικά:
          <ul class="grammar-el">
            <li>
              α) Λόγω απρόοπτων γεγονότων κειμένων πέρα των ανθρωπίνων αντικειμενικά δυνατοτήτων (ατύχημα, δυσμενείς 
              καιρικές συνθήκες, αργοπορία μεταφορικών μέσων κλπ.). 
            </li>
            <li>
              β) Λόγω πράξεως παραλείψεως ή λανθασμένων οδηγιών του  Αποστολέα ή του Παραλήπτη ή τρίτου προσώπου έχοντος συμφέρον από τη συγκεκριμένη αποστολή. 
            </li>
            <li>
              γ) Λόγω του περιεχομένου της αποστολής στο οποίο μπορεί να επέλθει ειδική βλάβη, αλλοίωση ή καταστροφή.
            </li>
          </ul>
        </p>
        <p class="p-align mt-1"> Δικαίωμα επιφύλαξης: Ο παραλήπτης, εάν υπάρχουν βάσιμοι προς τούτο λόγοι, έχει δικαίωμα να παραλάβει το αντικείμενο με επιφύλαξη, 
          καταγράφοντας τους σχετικούς λόγους της επιφύλαξης στην ιστοσελίδα της Εταιρείας στο σημείο παρακολούθησης της αποστολής (ΕΣΠΕΤΑ). 
          Η δήλωση επιφύλαξης μπορεί να πραγματοποιηθεί εντός μίας (1) ημέρας από την παραλαβή του αντικειμένου.</p>
        <div class="general-terms">
          3. <span>ΛΟΙΠΕΣ ΠΕΡΙΠΤΩΣΕΙΣ ΜΗ ΕΥΘΥΝΗΣ ΤΗΣ ΕΤΑΙΡΕΙΑΣ:</span>
        </div>
        <p class="p-align mt-1">
          A. Η Εταιρεία καταβάλλει κάθε προσπάθεια και προβαίνει σε κάθε ενέργεια για να πετύχει ταχύτατη παράδοση σύμφωνα με το πρόγραμμα λειτουργίας της.
        </p>
        <p class="p-align mt-1">
          Β.Η ευθύνη της Εταιρείας για τα προς μεταφορά έγγραφα ή αντικείμενα υπηρεσιών Ταχυμεταφορών παύει να ισχύει την στιγμή παράδοσης του εγγράφου 
          ή αντικειμένου στον Παραλήπτη αυτής αποδεικνυομένης με την υπογραφή του παραλαβόντος. Η υπογραφή του Παραλήπτη ή τρίτου σύνοικου μπορεί να 
          είναι επί του αποδεικτικού παραλαβής – παράδοσης ή ηλεκτρονικά στη συσκευή για τη λήψη των δεδομένων (στοιχείων) παραλαβής και παράδοσης αποστολών (PDA). 
          Ταυτόχρονα με την παράδοση της αποστολής και τη λήψη υπογραφής καταγράφονται και τα αντίστοιχα στοιχεία παράδοσης (ώρα, ημερομηνία και ονοματεπώνυμο ή/και 
          επωνυμία Παραλήπτη ή τρίτου σύνοικου).
        </p>
        <p class="p-align mt-1">
          Γ. Σε καμία περίπτωση η Εταιρεία δε φέρει ευθύνη για οποιαδήποτε ολική ή μερική καταστροφή, απώλεια, ζημία, βλάβη, 
          κακή παράδοση ή μη παράδοση οφειλόμενη σε απρόβλεπτες αιτίες, ιδίως:
          <ul class="roman">
            <li>
              Λόγω πράξεως παραλείψεως ή λανθασμένων οδηγιών του Αποστολέα ή του Παραλήπτη ή 	τρίτου προσώπου έχοντος συμφέρον από 
              τη συγκεκριμένη αποστολή, ή λανθασμένης 	περιγραφής του αντικειμένου ή/και ελλιπών ή λανθασμένων στοιχείων του Παραλήπτη.
            </li>
            <li>
              Λόγω του περιεχομένου της αποστολής στο οποίο μπορεί να επέλθει ειδική βλάβη, αλλοίωση ή καταστροφή, λόγω της ιδιαίτερης 
              ευπαθούς φύσης του περιεχομένου του ταχυδρομικού αντικειμένου ή δέματος, όπως ιδίως τρόφιμα με ημερομηνία λήξης και	φάρμακα.
            </li>
            <li>
              Λόγω ακατάλληλης συσκευασίας από τον Αποστολέα. Ως ακατάλληλη μεταξύ άλλων 	νοείται η συσκευασία η οποία κατά την μεταφορά 
              των αντικειμένων μαζί με ή κάτω από άλλα 	αντικείμενα μέσα σε σάκους, καρότσια και φορτηγά ή κατά την διάρκεια μεταφορτώσεων 
              δεν έχει την δυνατότητα να διατηρήσει τις εξωτερικές της διαστάσεις προφυλάσσοντας έτσι το περιεχόμενό της. Η Ευθύνη 
              της συσκευασίας ανήκει στον Αποστολέα.
            </li>
            <li>
              Λόγω περιπτώσεων Ανωτέρας Βίας 
            </li>
            <li>
              Λόγω περιπτώσεων:
              <ul class="grammar-el">
                <li>
                  α. Εκτάκτου ανάγκης, τις οποίες οι επιχειρήσεις δεν μπορούσαν να προβλέψουν ούτε να αποφύγουν. 
                  Στις περιπτώσεις αυτές, επιστρέφεται το καταβληθέν ταχυδρομικό τέλος.
                </li>
                <li>
                  β. Λόγω αποδεδειγμένων εγκληματικών ενεργειών στις εγκαταστάσεις ή στα μεταφορικά μέσα, που έχουν σαν αποτέλεσμα 
                  την απώλεια, βλάβη ή καταστροφή ταχυδρομικών αντικειμένων. Στις περιπτώσεις αυτές, επιστρέφεται το καταβληθέν ταχυδρομικό τέλος.
                </li>
                <li>
                  γ. Λόγω αποστολών, των οποίων το περιεχόμενο εμπίπτει σε απαγόρευση διακίνησης ή κατοχής για τον Αποστολέα και/ή τον Παραλήπτη, 
                  καθώς και αποστολών, το περιεχόμενο των οποίων κατάσχεται ή καταστρέφεται από τις αρμόδιες Αρχές.
                </li>
                <li>
                  δ. Λόγω του ότι ο Παραλήπτης δεν έχει ενδιαφερθεί για παραλαβή του ταχυδρομικού αντικειμένου για διάστημα άνω των έξι (6) μηνών.
                </li>
                <li>
                  ε. Λόγω του ότι ο Παραλήπτης παραλαμβάνει το αντικείμενο και εντός τριών (3) εργάσιμων ημερών δεν έχει εκφράσει επιφύλαξη.
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <p class="p-align mt-1">
          Δ. Η Εταιρεία δε φέρει καμία ευθύνη για αποζημίωση του χρήστη για οποιαδήποτε αποστολή και από οποιαδήποτε αιτία εφόσον ο χρήστης 
          δεν έχει εξοφλήσει κάθε οικονομική υποχρέωση που έχει προς την Εταιρεία εμπρόθεσμα βάσει της προβλεπόμενης μεταξύ τους συμφωνίας, 
          συμπεριλαμβανόμενων και των ασφαλίστρων. 
        </p>
        <p class="p-align mt-1">
          Ε. Η Εταιρεία δε φέρει καμία ευθύνη για αποζημίωση του χρήστη εφόσον ο χρήστη δεν προσκομίσει εμπρόθεσμα τα απαραίτητα 
          δικαιολογητικά τα οποία αποδεικνύουν την αξία των μεταφερομένων αποστολών προς αποζημίωση. 
        </p>
        <p class="p-align mt-1">
          Ζ. Η Εταιρεία δε φέρει καμία ευθύνη αποζημίωσης του χρήστη στην περίπτωση που η παράδοση αποστολής στον χρήστη ή η επιστροφή 
          της στον Αποστολέα δεν είναι εφικτή, λόγω άρνησης παραλαβής ή μη δυνατότητας εντοπισμού τους. 
        </p>
        <p class="p-align mt-1">
          Η. Η Εταιρεία διεκπεραιώνει αποστολές αντικαταβολών. Ειδικά η ημερομηνία αφορά αποκλειστικά συμφωνία του Αποστολέα με τον Παραλήπτη.
        </p>
        <p class="p-align mt-1">
          Θ. H Εταιρεία, κατόπιν αιτήματος του Παραλήπτη είναι δυνατόν να τροποποιήσει το σημείο παράδοσης (ανακατεύθυνση) ή το χρόνο 
          (ημέρα/ώρα) παράδοσης της αποστολής. Στην περίπτωση αυτή ενδέχεται, αναλόγως του σημείου παράδοσης ή και του χρόνου παράδοσης, να υπάρχει πρόσθετη χρέωση.
        </p>
        <div class="general-terms">
          4. <span>ΟΡΟΙ ΔΙΑΧΕΙΡΙΣΗΣ ΑΝΕΠΙΔΟΤΩΝ/ΑΠΑΡΑΔΟΤΩΝ ΑΝΤΙΚΕΙΜΕΝΩΝ:</span>
        </div>
        <p class="p-align mt-1">
          Α. Ανεπίδοτο θεωρείται ένα ταχυδρομικό αντικείμενο όταν η ταχυδρομική επιχείρηση έχει εξαντλήσει κάθε δυνατή προσπάθεια επίδοσής 
          του, χωρίς αποτέλεσμα. Συνεπώς, ως ανεπίδοτα αντικείμενα χαρακτηρίζονται από την Εταιρεία:
          <ul class="dash">
            <li>εκείνα τα οποία δεν κατέστη δυνατόν να παραδοθούν στον Παραλήπτη για οποιαδήποτε αιτία,</li>
            <li>εκείνα τα οποία έχουν παρακρατηθεί νόμιμα από την Εταιρεία και</li>
            <li>εκείνα τα οποία δεν κατέστη δυνατόν να παραδοθούν στον Παραλήπτη και ταυτόχρονα δεν είναι δυνατόν να ενημερωθεί ο Αποστολέας.</li>
          </ul>
        </p>
        <p class="p-align mt-1">
          Β. Σε περίπτωση ταχυδρομικών αντικειμένων για τα οποία δεν κατέστη δυνατή η επίδοσή τους στους Παραλήπτες για οποιαδήποτε αιτία, 
          η Εταιρεία οφείλει τουλάχιστον:
          <ul class="dash">
            <li>να προβεί σε έγγραφη ειδοποίηση προς τον Παραλήπτη και επιστροφή του αντικειμένου 	σε χώρο της επιχείρησης</li>
            <li>να προγραμματίσει δεύτερη ή και περισσότερες προσπάθειες επίδοσης κατόπιν 	συνεννόησης με τον Παραλήπτη ή/και τον Αποστολέα και 
              σε εναλλακτικά σημεία επίδοσης όπως αυτόματες ταχυδρομικές θυρίδες ή άλλα σημεία εξυπηρέτησης,
            </li>
            <li>να ανανεώσει το χρόνο παραμονής του αντικειμένου που βρίσκεται σε αυτόματη ταχυδρομική θυρίδα ο οποίος δεν μπορεί να είναι 
              μικρότερος των είκοσι τεσσάρων (24) ωρών.</li>
            <li>
              εφόσον τελικά δεν καταστεί δυνατή η επίδοση του αντικειμένου στον Παραλήπτη, το αντικείμενο δύναται να επιστρέφεται στον 
              Αποστολέα με χρέωση όχι μεγαλύτερη του ποσού 	που καταβλήθηκε για την αποστολή του.
            </li>
          </ul>
        </p>
        <p class="p-align mt-1">
          Γ. Η Εταιρεία διατηρεί τα ανεπίδοτα αντικείμενα, για τα οποία δεν είναι εφικτή η επιστροφή τους στον Αποστολέα, για διάστημα 
          έξι (6) μηνών μετά την παρέλευση των οποίων συντάσσεται ειδικό πρακτικό καταστροφής. Περιπτώσεις οι οποίες χρήζουν ειδικής 
          μεταχείρισης όπως αντικείμενα τα οποία αλλοιώνονται, καταστρέφονται άμεσα με την προηγούμενη σύνταξη ειδικού πρακτικού καταστροφής.
        </p>
        <p class="p-align mt-1">
          Δ. Η Εταιρεία κάνει ό,τι είναι δυνατόν για να παραδοθούν τα ανεπίδοτα αντικείμενα των ανωτέρω περιπτώσεων εντός του προκαθορισμένου 
          για παράδοση χρόνου. Σε περίπτωση που αυτό δεν καταστεί δυνατό, ενημερώνονται τα αρμόδια όργανα της Εταιρείας για την διαχείριση αυτών των αποστολών.
        </p>
        <p class="p-align mt-1">
          Ε. Από την παραπάνω διαδικασία εξαιρούνται τα αντικείμενα τα οποία έχουν παρακρατηθεί επίσημα ή έχει δηλωθεί από τον Αποστολέα ότι έχουν 
          αξία μείζονα των 100€ , οπότε αποδίδονται στην Ε.Ε.Τ.Τ.
        </p>
        <div class="general-terms">
          5. <span>ΜΗ ΑΠΟΔΕΚΤΑ ΤΑΧΥΔΡΟΜΙΚΑ ΑΝΤΙΚΕΙΜΕΝΑ:</span>
        </div>
        <p class="p-align mt-1">
          Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» δεν αναλαμβάνει την διεκπεραίωση και μεταφορά των παρακάτω εγγράφων και ταχυδρομικών αντικειμένων:
          <ul class="dash">
            <li>Χρήματα</li>
            <li>Εύθραυστα αντικείμενα μη επαρκώς συσκευασμένα</li>
            <li>Ενεργοποιημένες τραπεζικές κάρτες</li>
            <li>Κάρτες Χρόνου Σταθερής και Κινητής Τηλεφωνίας</li>
            <li>Χρυσό</li>
            <li>Πολύτιμους Λίθους & Μέταλλα</li>
            <li>Αρχαιότητες</li>
            <li>Έργα Τέχνη</li>
            <li>Μη Αναπαραγώγιμα Έγγραφα ή Αντικείμενα (π.χ. Αλληλογραφία/Φιλμ/Βίντεο Προσωπικής Αξίας, Γραμματόσημα, Λαχεία, Αντίκες)</li>
            <li>Αλλοιώσιμα Τρόφιμα</li>
            <li>Φυτά</li>
            <li>Ναρκωτικά</li>
            <li>Εύφλεκτα (υγρά και στερεά), Εκρηκτικά & Ραδιενεργά Υλικά</li>
            <li>Αέρια</li>
            <li>Οξειδωτικά και διαβρωτικά</li>
            <li>Βιολογικό Υλικό</li>
            <li>Μολυσματικές, Διαβρωτικές & Δηλητηριώδεις Ουσίες</li>
            <li>Είδη ή μέρη Οπλισμού</li>
            <li>Ζώα</li>
            <li>Νεκρούς και τέφρα</li>
            <li>Πορνογραφικό Υλικό</li>
            <li>Κάθε παράνομη ιδιοκτησία</li>
            <li>Γενικά Είδη των οποίων η μεταφορά απαγορεύεται από διεθνείς συμβάσεις ή Οργανισμούς (ΙΑΤΑ, ICAO) ή/και από διατάξεις της Κοινοτικής ή/και Εθνικής νομοθεσία</li>
          </ul>
        </p>
        <p class="p-align mt-1">
          Επίσης, διατηρεί τη δυνατότητα να αρνηθεί να μεταφέρει αντικείμενα τα οποία κατά την κρίση της κρίνονται επικίνδυνα, πιθανώς υπαγόμενα 
          και στις ανωτέρω κατηγορίες, και δύνανται να μεταφερθούν υπό συγκεκριμένους όρους και προϋποθέσεις.
        </p>
        <p class="p-align mt-1">
          Η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» διατηρεί το δικαίωμα να ελέγξει το περιεχόμενο του υπό διακίνηση αντικειμένου και ο Αποστολέας δηλώνει υπεύθυνα 
          ότι η αποστολή δεν περιλαμβάνει κάποιο από τα παραπάνω είδη.
        </p>
        <div class="general-terms">
          6. <span>ΑΣΦΑΛΙΣΗ:</span>
        </div>
        <p class="p-align mt-1">
          Ρητά συμφωνείται ότι σε περίπτωση που o Εντολέας ή ο Αποστολέας ή τρίτος προβεί στην ασφάλιση των μεταφερόμενων με την «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» 
          αντικειμένων σε άλλη ασφαλιστική εταιρεία της δικής του επιλογής, σύμφωνα με τα αναφερόμενα ανωτέρω στις παραγράφους, η ευθύνη της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» 
          συνολικά έναντι της εκάστοτε ασφαλιστικής εταιρείας καθώς και οιουδήποτε άλλου τρίτου δεν θα υπερβαίνει τα ποσά που ρητά αναφέρονται στις παραγράφους αυτές. 
          Αυτόδηλο τυγχάνει ότι απαραίτητη προϋπόθεση για την καταβολή από την «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» οποιουδήποτε ποσού αποζημίωσης είναι η με αποδεδειγμένη ευθύνη 
          της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ», καταστροφή, απώλεια ή κλοπή του αντικειμένου. Σε περίπτωση ασφάλισης των μεταφερομένων ειδών σε ασφαλιστική εταιρεία ή με 
          ασφαλιστήριο συμβόλαιο επιλογής του Εντολέα ή του Αποστολέα ή τρίτου, αυτός δηλώνει και εγγυάται στην «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» ότι στην ασφάλιση έχει 
          περιληφθεί ο όρος παραίτησης του αναγωγικού δικαιώματος της ασφαλιστικής εταιρείας κατά του μεταφορέα, είναι δε υπεύθυνος έναντι της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» 
          για κάθε ζημιά που τυχόν υποστεί από τη μη αναγραφή του ανωτέρου όρου στην ασφαλιστική κάλυψη.
        </p>
      </div>
    </div>

    <!-- ΚΕΦΑΛΑΙΟ 5 -->
    <div class="xyk-container entity flex-start">
      <div class="head">
        <h3>ΚΕΦΑΛΑΙΟ 5</h3>
        <span>{{ contents[4] }}</span>
      </div>
      <p class="p-align mt-1">
        Το Κέντρο Εξυπηρέτησης Πελατών είναι αρμόδιο για την πληροφόρηση των χρηστών για κάθε απορία ή την πρόοδο μιας αποστολής ή μιας αποζημίωσης ή ακόμα και 
        της λειτουργίας της Εταιρείας ή και οποιοδήποτε τυχόν πρόβλημα ή παράπονο που αφορά στην εξυπηρέτησή του στο τηλέφωνο 2111982107 ή μέσω ηλεκτρονικού 
        ταχυδρομείου στη διεύθυνση help@skroutzlastmile.gr ή μέσω αποστολής μηνύματος στη θυρίδα αυτοματοποιημένης επικοινωνίας (chatbox) που βρίσκεται στην 
        ιστοσελίδα της Εταιρείας www.skroutzlastmile.gr. Σε κάθε περίπτωση, η Εταιρεία απαντά σε αίτημα χρήστη άμεσα και το αργότερο μέσα σε διάστημα 21 ημερολογιακών 
        ημερών. Ως διαφορά ορίζεται η αποδεδειγμένη αθέτηση από την «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» του παρόντος Χ.Υ.Κ.
      </p>
      <div class="bussiness-specs">
        <p>
          Η διαδικασία επίλυσης διαφορών περιλαμβάνει τα εξής διακριτά στάδια: 
          <ul class="dash">
            <li>
              <u>Φιλικός Διακανονισμός</u>: Έγγραφη επικοινωνία του χρήστη με τη «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» προκειμένου να επιτευχθεί επίλυση της 
              διαφοράς μεταξύ των δύο μερών, εντός των προβλεπόμενων στον Χ.Υ.Κ. ή και με την σύμφωνη γνώμη των μερών. Ο φιλικός διακανονισμός 
              επιτυγχάνεται με τους όρους και τις προϋποθέσεις, όπως περιεγράφησαν ανωτέρω. Για περισσότερες πληροφορίες, ο χρήστης των υπηρεσιών 
              της Εταιρείας μπορεί να αποτανθεί στο Κέντρο Εξυπηρέτησης Πελατών, κατά τις εργάσιμες ημέρες και εντός του ωραρίου εργασίας, 
              στον ακόλουθο αριθμό 2111982107.
            </li>
            <li>
              <u>Επιτροπή Επίλυσης Διαφορών</u>: Οι διαφορές που μπορεί να αναφύονται ανάμεσα στους χρήστες και στην «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ», μπορούν να επιλύονται 
              σε επόμενο στάδιο και από την Επιτροπή Επίλυσης Διαφορών. Έπειτα από αίτημα του χρήστη η Εταιρεία συγκροτεί την Επιτροπή Επίλυσης Διαφορών η οποία 
              αποτελείται από μέλη της επιχείρησης και εκπρόσωπο των καταναλωτών, ενώ στις συνεδριάσεις μπορεί να παρίσταται ή να εκπροσωπείται από ειδικά εξουσιοδοτημένο 
              πρόσωπο, ο χρήστης. Η Εταιρεία ενημερώνει τον χρήστη για τον τόπο και το χρόνο των συνεδριάσεων της Επιτροπής και για το δικαίωμά του να υποβάλει 
              γραπτό υπόμνημά του σε περίπτωση που κωλύεται να παραστεί. Οι χρήστες ταχυδρομικών υπηρεσιών, εφόσον το επιθυμούν μπορούν να ασκήσουν τα δικαιώματά τους, 
              εντός χρονικής περιόδου έξι (6) μηνών από την κατάθεση του αποστελλόμενου αντικειμένου. Η Εταιρεία ενημερώνει τον χρήστη για τον τόπο και το χρόνο των 
              συνεδριάσεων της Επιτροπής και για το δικαίωμά του να υποβάλει γραπτό υπόμνημά του σε περίπτωση που κωλύεται να παραστεί ενώ εντός τριάντα (30) ημερών 
              εκδίδεται η σχετική απόφαση. Οι χρήστες ταχυδρομικών υπηρεσιών, εφόσον το επιθυμούν μπορούν να ασκήσουν τα δικαιώματά τους, εντός χρονικής περιόδου έξι (6) 
              μηνών από την κατάθεση του αποστελλόμενου αντικειμένου.
            </li>
          </ul>
        </p>
      </div>
    </div>

    <!-- ΚΕΦΑΛΑΙΟ 6 -->
    <div class="xyk-container entity flex-start">
      <div class="head">
        <h3>ΚΕΦΑΛΑΙΟ 6</h3>
        <span>{{ contents[5] }}</span>
      </div>
      <p class="p-align mt-1">
        <u>Συμπεριφορά – Σεβασμός – Ευπρέπεια Προσωπικού</u>
      </p>
      <p class="p-align mt-1">
        Το προσωπικό της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» είναι υποχρεωμένο να συμπεριφέρεται με σεβασμό στους χρήστες κατά την διάρκεια της επαφής 
        του με αυτούς. Παράπονα χρηστών για ανάρμοστη ή αγενή συμπεριφορά, ελλιπή εξυπηρέτηση μπορούν να γίνονται είτε προφορικά στον υπεύθυνο 
        του Τμήματος Εξυπηρέτησης Πελατών στο τηλέφωνο 2111982107 ή με γραπτή αναφορά μέσω επιστολικού ταχυδρομείου ή με αποστολή email στην 
        διεύθυνση email: <a href="mailto:help@skroutzlastmile.gr">help@skroutzlastmile.gr</a> ή και μέσω της ιστοσελίδας της Εταιρείας 
        <a href="www.skroutzlastmile.gr">www.skroutzlastmile.gr</a>.
      </p>
      <p class="p-align mt-1">
        <u>Διευκόλυνση Ατόμων με Ειδικές Ανάγκες</u>
      </p>
      <p class="p-align mt-1">
        Για άτομα με ειδικές ανάγκες έχει γίνει κάθε δυνατή προσπάθεια για την καλύτερη πρόσβαση αυτών στους χώρους της Εταιρείας 
        μέσω ειδικών – εύκολα προσβάσιμων - διαδρόμων.
      </p>
      <p class="p-align mt-1">
        <u>Διαρκής Εκσυγχρονισμός</u>
      </p>
      <p class="p-align mt-1">
        Στο πλαίσιο του διαρκούς εκσυγχρονισμού της λειτουργίας της η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ», έχει προγραμματίσει και υλοποιεί ένα ευρείας μορφής 
        εκπαιδευτικό πρόγραμμα σε όλο το προσωπικό της, ανεξαρτήτως βαθμίδας ιεραρχίας και θέσης στην οποία απασχολείται. Το εκπαιδευτικό πρόγραμμα το οποίο έχει 
        σχεδιαστεί και εφαρμόζεται, προβλέπει την εκπαίδευση όλου του προσωπικού με στόχο την καλύτερη εξυπηρέτηση του Χρήστη των υπηρεσιών μας, σε μία προσπάθεια παροχής 
        της βέλτιστης εμπειρίας κατά τη διάρκεια χρήσης αυτών. Τα θέματα ενδεικτικώς αφορούν στην ασφάλεια, την υγιεινή, την πυρασφάλεια, τα προσωπικά δεδομένα, αλλά 
        και πολλά άλλα ζητήματα που ανακύπτουν κατά την παροχή των υπηρεσιών.
      </p>
      <p class="p-align mt-1">
        Στο πλαίσιο της συνεχούς βελτίωσης των υπηρεσιών μας, η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» προβαίνει σε ένα σύνολο συνεχών επενδύσεων που αφορούν στον 
        εκσυγχρονισμό του μηχανογραφικού συστήματος διαχείρισης και παρακολούθησης των αποστολών online.
      </p>
    </div>

    <!-- ΚΕΦΑΛΑΙΟ 7 -->
    <div class="xyk-container entity flex-start">
      <div class="head">
        <h3>ΚΕΦΑΛΑΙΟ 7</h3>
        <span>{{ contents[6] }}</span>
      </div>
      <div class="bussiness-specs">
        <p>
          Ο Χάρτης Υποχρεώσεων προς Καταναλωτές δεν ισχύει σε περιπτώσεις: 
          <ul class="dash">
            <li>
              Ανωτέρας βίας και ιδιαίτερα σε έκτακτα γεγονότα των οποίων οι συνέπειες επηρεάζουν αρνητικά τη λειτουργία της «ΣΚΡΟΥΤΖ 
              ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» (απεργίες, δυσμενείς καιρικές συνθήκες, συγκοινωνιακά ή τηλεπικοινωνιακά προβλήματα κ.λπ.). 
            </li>
            <li>
              Εγκληματικών ενεργειών στις εγκαταστάσεις ή στα μεταφορικά μέσα, που έχουν σαν αποτέλεσμα την απώλεια αντικειμένων ειδικής διαχείρισης. 
            </li>
            <li>
              Αδυναμίας πρόσβασης υπαλλήλου της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» στη διεύθυνση του Παραλήπτη, η οποία οφείλεται στον 
              Αποστολέα ή σε οποιονδήποτε τρίτο.
            </li>
            <li>
              Μη εκπλήρωσης των συμβατικών υποχρεώσεων των χρηστών (σε περιπτώσεις συμβάσεων μεταξύ «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» 
              και χρηστών), μεταξύ των οποίων και οι οικονομικές υποχρεώσεις των χρηστών προς την «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ». 
            </li>
            <li>
              Ενεργειών που αντίκεινται στην ελληνική νομοθεσία. 
            </li>
            <li>
              Μη υλοποίησης της υποχρέωσης της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» λόγω υπαιτιότητας ή επιθυμίας του χρήστη). 
            </li>
            <li>
              Αποδεδειγμένα ανυπόστατου αιτήματος. 
            </li>
            <li>
              Μη εκπλήρωσης οποιασδήποτε υποχρέωσης του χρήστη που προβλέπεται από το Χ.Υ.Κ. 
            </li>
            <li>
              Μη εκπλήρωσης της υποχρέωσης της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» εξαιτίας ενεργειών ή παραλείψεων άλλων φορέων, με τους 
              οποίους η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» συνεργάζεται. 
            </li>
            <li>
              Αποδεδειγμένης υπαιτιότητας του χρήστη ή τρίτου προσώπου. 
            </li>
            <li>
              Αποδεδειγμένης αδυναμίας της «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ».
            </li>
          </ul>
        </p>
      </div>
      <p class="p-align mt-1">
        Στις ανωτέρω περιπτώσεις η «ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ» δεν φέρει καμία ευθύνη έναντι του Αποστολέα /Παραλήπτη / χρήστη.
      </p>
    </div>
  </section>

  <Footer />
</div>

</template>

<script>
  import Navbar from '../pages-sections/Navbar.vue';
  import Footer from '../pages-sections/Footer.vue';

  const PERIEXOMENA = [
    'Χαρακτηριστικά της ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ',
    'Υπηρεσίες που παρέχει η ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ',
    'Πολιτική Ποιότητας της ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ',
    'Ατομική Σύμβαση με τον Χρήστη Υπηρεσιών της ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ',
    'Διαδικασία Επίλυσης Διαφορών',
    'Λοιπές Προδιαγραφές της ΣΚΡΟΥΤΖ ΥΠΗΡΕΣΙΕΣ ΔΙΑΝΟΜΗΣ',
    'Περιπτώσεις κατά τις οποίες δεν ισχύει ο Χ.Υ.Κ.'
  ];

  export default {
    name: 'XYK',
    components: {
      Navbar,
      Footer
    },
    data() {
      return {
        contents: PERIEXOMENA
      }
    }
  }
</script>

<style lang="scss" scoped>
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: unset;
    border-radius: unset;
  }
  .xyk-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 600px;
    width: 100%;
    max-width: 780px;
    
    &.flex-start {
      align-items: flex-start;
    }
  }
  #first-page {
    justify-content: space-around;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h2 {
      text-align: center;
      color: purple;
      font-weight: bold;
      line-height: 40px;
    }
  }
  .contents {
    margin-top: 20px;
    
    table {
      margin-top: 10px;

      td {
        padding: 4px 0;
        
        &.ml {
          padding-left: 20px;
        }
      }
    }
  }
  .entity {
    margin-top: 4rem;
    .head {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      h3 {
        font-weight: bold;
        margin-right: 1rem;
        font-size: 1.3em;
        white-space: nowrap;
      }
    }

    .p-align {
      text-align: justify;
    }
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .bussiness-tb {
    margin: 1rem 0;
    
    td {
      border: 1px solid #00000024;
      padding: 4px;
      width: 50%;
    }
  }
  .bussiness-specs {
    margin-top: 1rem;

    h5 {
      font-weight: bold;
      font-size: 1.2em;
    }
    p {
      margin: 0.5rem 0;
    }
    ul {
      margin-left: 2rem;
      li {
        list-style-type: disc;
        margin-top: 12px;
        list-style-position: outside;
        
        &::before {
          content: "";
          width: 15px;
          display: inline-block;
        }
      }
    }
    .ol > li {
      list-style-type: decimal;
      &::before {
        width: 20px;
      }
    }
    .dash > li {
      list-style-type: none;
      &::before {
        content: "-";
        width: 20px;
      }
    }
    .grammar-el > li {
      list-style-type: none;
      &::before {
        width: 20px;
      }
    }
    .roman > li {
      list-style-type: lower-roman;
    }

    .service-cases {
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        margin-top: 1rem;
      }
    }
  }
  .general-terms {
    font-weight: bold;
    margin: 2rem 1rem 1rem 1rem;
    
    span {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 733px) {
    h2 {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .delivery-list {
  list-style-type: decimal;
  padding-left: 20px;
  margin-top: 10px;
}
.delivery-list strong {
  font-weight: bold;
  color: #000; 
}
</style>
